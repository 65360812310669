const firebaseConfig = {
  apiKey: "AIzaSyBB6mggW1d-62QN41sQWpB5g60IcnYnIYY",
  authDomain: "sales-sandbox-a59a7.firebaseapp.com",
  databaseURL: "https://sales-sandbox-a59a7.firebaseio.com",
  projectId: "sales-sandbox-a59a7",
  storageBucket: "sales-sandbox-a59a7.appspot.com",
  messagingSenderId: "543556778786",
  appId: "1:543556778786:web:4d55fdf6b2584b6eaac37b",
  measurementId: "G-4JN9RS6JB4",
};

export default firebaseConfig;
