import React, { useState } from 'react';
import {
  Tooltip,
  Snackbar,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Error from './Error';

const styles = ({ palette }: Theme) => createStyles({
  code: {
    fontSize: '2em',
    border: '1px dashed gray',
    marginTop: 15,
    marginBottom: 15,
    padding: 15,
    width: '100%',
    margin: 'auto',
    cursor: 'pointer',
    display: 'block',
    textAlign: 'center',
    borderRadius: '2px',
    transitionProperty: 'background-color',
    transitionDuration: '0.25s',
    '&:hover': {
      backgroundColor: palette.grey[700],
    }
  },
});

const useStyles = makeStyles(styles);

interface PromoResultBoxProps {
  error?: Error,
  codes: string[],
}

function PromoResultBox({ error, codes = [] }: PromoResultBoxProps) {
  const classes = useStyles();
  const [success, setSuccess] = useState<boolean | null>(null);

  if (error) {
    return <Error message={`${error.name} ${error.message}`} />;
  }

  return (
    <>
      {
        codes.map((code: string) => (
          <Tooltip title="Copy to clipboard">
            <code
              className={classes.code}
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(code);
                  setSuccess(true);
                } catch (error) {
                  setSuccess(false);
                }
              }}
            >
              {code}
            </code>
          </Tooltip>
        ))
      }
      {
        codes.length > 1 && (
          <Tooltip title="Copy all to clipboard">
            <Button
              color="primary"
              startIcon={<FileCopyIcon />}
              variant="contained"
              fullWidth
              onClick={async () => {
                try {
                  await navigator.clipboard.writeText(codes.join('\n'));
                  setSuccess(true);
                } catch (error) {
                  setSuccess(false);
                }
              }}
            >
              Copy all
            </Button>
          </Tooltip>
        )
      }
      <Snackbar
        open={Boolean(success)}
        autoHideDuration={3000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          severity="success"
          onClose={() => setSuccess(false)}
        >
          Copied!
        </Alert>
      </Snackbar>
    </>
  );
}

export default PromoResultBox;
