import React, { useState, MouseEvent } from 'react';
import { Menu, MenuItem, Button, IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import 'firebase/auth';
import { useAuth } from '../hooks/useAuth';

function LoginMenu() {
  const { user, loading, signIn, signOut } = useAuth();
  const [ open, setOpen ] = useState(false);
  const [ anchor, setAnchor ] = useState<Element|null>(null);

  const handleMenu = (event: MouseEvent) => {
    setAnchor(event.currentTarget)
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const style = {
    marginLeft: 24,
  };

  if (loading) {
    return null;
  }

  if (user) {
    return (
      <>
        <IconButton
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
          style={style}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem disabled>{user.email}</MenuItem>
          <MenuItem onClick={() => { signOut(); handleClose(); }}>Sign Out</MenuItem>
        </Menu>
      </>
    );
  }

  return <Button color="inherit" onClick={signIn} style={style}>Sign In</Button>
};

export default LoginMenu;