import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import firebaseSandboxConfig from './firebaseSandboxConfig';
import Main from './pages/Main';

import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import cyan from '@material-ui/core/colors/cyan';
import 'typeface-roboto';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: cyan,
    secondary: {
      main: '#000000',
    },
  },
});

firebase.initializeApp(firebaseConfig);
firebase.initializeApp(firebaseSandboxConfig, 'sandbox');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Main />
        </CssBaseline>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
