import React, { useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Fab,
  Tooltip,
  LinearProgress,
} from '@material-ui/core';
import {
  DataGrid,
  GridOverlay,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridCellParams,
} from '@material-ui/data-grid';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { useFetch } from 'use-http';
import httpStatusCodes from 'http-status-codes';
import { AddNewPromoDialog } from '../components';
import constants from '../constants';
import { useAuth } from '../hooks/useAuth';

const { ENDPOINT_SALES } = constants;

interface Props {
  drawerOpen: boolean,
  setDrawerOpen: (open: boolean) => void,
};

function dateFormatter(params: GridValueFormatterParams) {
  if (!params.value) {
    return null;
  }

  return new Date(params.value as string).toLocaleString();
}

const columns: GridColDef[] = [
  {
    field: 'isConsumed',
    headerName: 'Consumed',
    width: 130,
    valueGetter: (params: GridValueGetterParams) =>
      Boolean(params.getValue(params.id, 'consumedAt')),
    renderCell: (params: GridCellParams) => {
      return (
        <>
          { Boolean(params.getValue(params.id, 'consumedAt')) && <DoneIcon color="primary" /> }
        </>
      );
    }
  },
  { field: 'id', headerName: 'Code', width: 130 },
  { field: 'type', headerName: 'Type', width: 130 },
  { field: 'promoType', headerName: 'Promo Type', width: 130 },
  { field: 'from', headerName: 'From', width: 130 },
  { field: 'to', headerName: 'To', width: 130 },
  { field: 'mac', headerName: 'Mac', width: 150 },
  { field: 'orderNumber', headerName: 'Order', width: 200 },
  { field: 'reason', headerName: 'Reason', width: 200 },
  {
    field: 'createdAt',
    headerName: 'Created at',
    width: 200,
    valueFormatter: dateFormatter,
  },
  { field: 'createdBy', headerName: 'Created by', width: 200 },
  { field: 'consumedByMac', headerName: 'Mac (consumed)', width: 180 },
  { field: 'consumedByUser', headerName: 'User (consumed)', width: 210 },
  {
    field: 'consumedAt',
    headerName: 'Consumed at',
    width: 200,
    valueFormatter: dateFormatter
  },
  {
    field: 'depletedAt',
    headerName: 'Depleted at',
    width: 200,
    valueFormatter: dateFormatter
  },
  { field: 'comment', headerName: 'Comment', width: 200 },
];

function LoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

function ErrorOverlay({ name: httpError }) {
  if (!httpError) {
    return (
      <GridOverlay>
        <code>Unknown error</code>
      </GridOverlay>
    );
  }

  return (
    <GridOverlay>
      <code>{httpError}: {httpStatusCodes.getStatusText(httpError)}</code>
    </GridOverlay>
  );
}

function Promos({ drawerOpen, setDrawerOpen }: Props) {
  const { claims } = useAuth();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now());
  const [page, setPage] = useState(0);
  const { loading, data, error } = useFetch(
    `${ENDPOINT_SALES}/promos`,
    {},
    [currentTimestamp, claims],
  );

  const formattedData = data?.data
    ?.map((item: any) => ({
      ...item,
      id: item.code,
    })) ?? [];

  return (
    <main style={{ width: '90vw', margin: 'auto', marginTop: 25 }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Promo Codes" />
            <CardContent style={{ height: 800 }}>
              <DataGrid
                loading={loading}
                error={error}
                rows={formattedData}
                columns={columns}
                pagination
                page={page}
                onPageChange={(page) => {
                  setPage(page);
                }}
                autoPageSize
                sortModel={[
                  {
                    field: 'createdAt',
                    sort: 'desc',
                  }
                ]}
                checkboxSelection
                disableSelectionOnClick
                components={{
                  LoadingOverlay: LoadingOverlay,
                  ErrorOverlay: ErrorOverlay,
                }}
                onFilterModelChange={() => setPage(0)}
              />
            </CardContent>
            {
              (claims.editor || claims.admin) && (
                <>
                  <CardActions>
                    <Tooltip title="Add new promo code">
                      <span>
                        <Fab color="primary" disabled={loading} aria-label="add" onClick={() => setDialogOpen(true)}>
                          <AddIcon />
                        </Fab>
                      </span>
                    </Tooltip>
                  </CardActions>
                  <AddNewPromoDialog
                    open={dialogOpen}
                    onClose={() => {
                      setCurrentTimestamp(Date.now());
                      setDialogOpen(false);
                    }}
                  />
                </>
              )
            }
          </Card>
        </Grid>
      </Grid>
    </main>
  );
}

export default Promos;
