import React from 'react';
import {
  Grid,
} from '@material-ui/core';

interface ErrorProps {
  message: string,
}

function Error({ message }: ErrorProps) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <code>{message}</code>
      </Grid>
    </Grid>
  );
}

export default Error;