const firebaseConfig = {
  apiKey: "AIzaSyD2xzxAKfdkNld6zdFtI7f0ppZOxkrVs5g",
  authDomain: "radinn-sales.firebaseapp.com",
  databaseURL: "https://radinn-sales.firebaseio.com",
  projectId: "radinn-sales",
  storageBucket: "radinn-sales.appspot.com",
  messagingSenderId: "773054608881",
  appId: "1:773054608881:web:49faf23c040019305c4050"
};

export default firebaseConfig;
