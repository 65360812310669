import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { Provider, CachePolicies } from 'use-http';
import { useAuth } from '../hooks/useAuth';
import TitleBar from '../components/TitleBar';
import LoginMenu from '../components/LoginMenu';
import mainStyle from '../styles/main';
import Error from '../components/Error';
import LinkButton from '../components/LinkButton';
import Promos from './Promos';

const useStyles = makeStyles(mainStyle);

function Main() {
  const app = getApp(process.env?.REACT_APP_GCP_PROJECT?.includes('sandbox') ? 'sandbox' : '[DEFAULT]');
  const auth = getAuth(app);
  const { user, loading, error } = useAuth();
  const [ drawerOpen, setDrawerOpen ] = useState(false);

  const classes = useStyles();

  if (loading) {
    return <LinearProgress variant="indeterminate" />;
  }

  if (error) {
    return <Error message={`Failed to login: ${error}`} />;
  }

  const globalOptions = {
    interceptors: {
      request: async ({ options }: any) => {
        const token = await auth?.currentUser?.getIdToken();

        if (token) {
          options.headers = {
            ...options.headers,
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          };
        }

        return options;
      },
    },
    cachePolicy: CachePolicies.NO_CACHE,
    persist: false,
  };

  return (
    <Provider options={globalOptions}>
      <TitleBar
        onMenuToggle={() => setDrawerOpen(!drawerOpen)}
      >
        <div className={classes.linkContainer}>
          <LinkButton className={classes.logo} path="/">
            <img
              alt="Radinn logo"
              src="https://cdn.radinn.com/-/media/Project/Radinn/Radinn/Logo-Images/Radinn_H_BB_RGB_black.svg"
            />
          </LinkButton>
          {/* {
            routes.filter(route => route.text).map(route => (
              <LinkButton key={route.path} path={route.path}>{route.text}</LinkButton>
            ))
          } */}
        </div>
        <LoginMenu />
      </TitleBar>
      {
        user
          ? (
            <>
              <Switch>
                <Route path="/promos">
                  <Promos drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
                </Route>
              </Switch>
              <Redirect to="/promos" />
            </>
          )
          : (
            <Error message="Unauthorized - please sign in." />
          )
      }
    </Provider>
  );
}

export default Main;
