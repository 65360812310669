import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
  IconButton,
  InputAdornment,
  LinearProgress,
  Snackbar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import ResetIcon from '@material-ui/icons/RotateLeft';
import Alert from '@material-ui/lab/Alert';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useFetch } from 'use-http';
import PromoResultBox from './PromoResultBox';
import { capitalize } from '../utils/stringFormatters';
import constants from '../constants';

const { ENDPOINT_SALES } = constants;

const styles = {
  dropdown: {
    marginTop: 10,
    marginBottom: 10,
  },
};

const useStyles = makeStyles(styles);

interface DialogProps {
  open: boolean,
  onClose: () => void,
};

interface FromAndToType {
  type: EPromoType,
  value: EFromType|EToType,
  level: number,
}

enum EPromoType {
  Jetpack = 'Jetpack',
  Battery = 'Battery',
};

enum EFromType {
  Standard = 'Standard',
  S = 'S',
  L = 'L',
  Any = '*',
}

enum EToType {
  Pro = 'Pro',
  Unleashed = 'Unleashed',
  L = 'L',
  XL = 'XL',
}

const fromTypes = [
  {
    type: EPromoType.Jetpack,
    value: EFromType.Standard,
    level: 1,
  },
  {
    type: EPromoType.Battery,
    value: EFromType.S,
    level: 1,
  },
  {
    type: EPromoType.Battery,
    value: EFromType.L,
    level: 2,
  },
  {
    type: EPromoType.Jetpack,
    value: EFromType.Any,
    level: 1,
  },
  {
    type: EPromoType.Battery,
    value: EFromType.Any,
    level: 1,
  }
] as FromAndToType[];

const toTypes = [
  {
    type: EPromoType.Jetpack,
    value: EToType.Pro,
    level: 2,
  },
  {
    type: EPromoType.Jetpack,
    value: EToType.Unleashed,
    level: 3,
  },
  {
    type: EPromoType.Battery,
    value: EToType.L,
    level: 2,
  },
  {
    type: EPromoType.Battery,
    value: EToType.XL,
    level: 3,
  }
] as FromAndToType[];

enum EReasons {
  LateDelivery = 'Late delivery',
  ReturnedDelivery = 'Returned delivery',
  QualityIssue = 'Quality issue',
  CustomsIssue = 'Customs issue',
  WrongContent = 'Wrong content',
  DamagedShipment = 'Damaged shipment',
  Promotion = 'Promotion',
  OrderMistake = 'Order mistake',
}

const macRegex = /^(0x)?(([0-9A-Fa-f]{2}):?){6}$/g;
const onrRegex = /(^N\/A$)|(^(RAD|REP|RAI|RGW)[A-Z]{2}P-B2[A-Z][\d]{7}$)/g;

const validationSchema = yup.object().shape({
  type: yup
    .string()
    .oneOf(Object.values(EPromoType))
    .required(),
  from: yup
    .string()
    .when('type', {
      is: EPromoType.Jetpack,
      then: yup.string().oneOf(Object.values(EFromType)).required(),
    })
    .when('type', {
      is: EPromoType.Battery,
      then: yup.string().oneOf(Object.values(EFromType)).required(),
    })
    .required(),
  to: yup
    .string()
    .test('oneOfCorrectToTypes', function(item) {
      if (!item || item.length === 0) {
        return false;
      }

      const from = fromTypes.find(fromType =>
        fromType.type === this.parent.type &&
        this.parent.from === fromType.value
      ) as FromAndToType;

      return toTypes
        .filter(toType =>
          toType.type === this.parent.type &&
          toType.level > (from?.level ?? 1)
        )
        .map(toType => toType.value)
        .includes(item as EToType)
    }).required(),
  reason: yup
    .string()
    .oneOf(Object.values(EReasons))
    .required(),
  mac: yup
    .string()
    .when('onr', {
      is: (onr: string|undefined) => !onr,
      then: yup
        .string()
        .matches(macRegex, 'Invalid format')
        .required('Mac or order number is required'),
      otherwise: yup
        .string()
        .matches(macRegex, 'Invalid format')
        .notRequired(),
    }),
  onr: yup
    .string()
    .when('mac', {
      is: (mac: string|undefined) => !mac,
      then: yup
        .string()
        .matches(onrRegex, 'Invalid format')
        .required('Mac or order number is required'),
      otherwise: yup
        .string()
        .matches(onrRegex, 'Invalid format')
        .notRequired(),
    }),
  comment: yup
    .string()
    .notRequired(),
  amount: yup
    .number()
    .when('mac', {
      is: undefined,
      then: yup.number().min(1),
      otherwise: yup.number().min(1).max(1, 'Max 1 code per mac'),
    })
    .required()
    .positive()
    .integer()
}, [['mac', 'onr']]);

function AddNewPromoDialog({ open, onClose }: DialogProps) {
  const classes = useStyles();
  const [complete, setComplete] = useState(false);
  const [codes, setCodes] = useState([]);

  const { post, response, loading, error } = useFetch(ENDPOINT_SALES)
  const { get, loading: deviceLoading, error: deviceError } = useFetch(ENDPOINT_SALES)

  const formik = useFormik({
    initialValues: {
      type: EPromoType.Jetpack,
      from: EFromType.Standard,
      to: EToType.Pro,
      reason: '',
      mac: '',
      onr: '',
      comment: '',
      amount: 1,
    },
    validationSchema,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (values.mac) {
        values.mac = `0x${values.mac.replace(/:/g, '').replace(/0x/g, '').toUpperCase()}`;
      }

      const data = await post('/promos/goodwill', values);

      if (response.ok) {
        setCodes(data?.data?.codes);
      }

      setComplete(true);
    },
  });

  const { setFieldValue } = formik;

  useEffect(() => {
    const firstValidFromType = fromTypes
      .find(item => item.type === formik.values.type)?.value;
    const highestValidToType = toTypes
      .filter(item => item.type === formik.values.type)
      .sort((a, b) => b.level - a.level)?.[0]?.value;

    if (firstValidFromType) {
      setFieldValue('from', firstValidFromType);
      setFieldValue('to', highestValidToType);
    }
  }, [setFieldValue, formik.initialValues, formik.values.type]);

  useEffect(() => {
    if (open) {
      setComplete(false); // show close button only when closing
    }
  }, [open])

  function handleReset() {
    setFieldValue('type', EPromoType.Jetpack);
    setFieldValue('from', EFromType.Standard);
    setFieldValue('to', EToType.Pro);
    setFieldValue('mac', '');
    setFieldValue('onr', '');
    setFieldValue('reason', '');
    setFieldValue('comment', '');
    setFieldValue('amount', 1);
    setComplete(false);
    setCodes([]);
  }

  function handleResetAndClose() {
    handleReset();
    onClose();
  }

  async function handleLookupMac() {
    let mac = formik.values.mac;

    if (formik.values.mac) {
      mac = `0x${formik.values.mac.replace(/:/g, '').replace(/0x/g, '').toUpperCase()}`;
    }

    const data = await get(`/ecom/serialnumbers/${mac}`);

    const highestValidToType = toTypes
      .filter(item => item.type === capitalize(data?.data?.type))
      .sort((a, b) => b.level - a.level)?.[0]?.value;

    setFieldValue('type', capitalize(data?.data?.type ?? ''));
    setFieldValue('from', data?.data?.currentVariant ?? '');
    setFieldValue('to', highestValidToType ?? '');
    setFieldValue('onr', data?.data?.orderInfo?.confirmationId ?? '');
  }

  return(
    <>
      <Dialog
        open={open}
        onClose={formik.handleReset}
        aria-labelledby="add-promo-dialog"
        disableBackdropClick
        disableEscapeKeyDown
      >
        { loading || deviceLoading ? <LinearProgress /> : null }
        <DialogTitle id="add-new-goodwill-promo">
          Create new goodwill promo
        </DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              autoFocus
              margin="dense"
              id="mac"
              name="mac"
              label="Mac"
              type="text"
              fullWidth
              value={formik.values.mac}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.mac)}
              disabled={deviceLoading || complete}
              helperText={capitalize(formik.errors.mac)}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                  <Tooltip title="Auto fill">
                    <span>
                      <IconButton
                        aria-label="lookup mac"
                        disabled={!formik.values.mac || Boolean(formik.errors.mac)}
                        onClick={handleLookupMac}
                      >
                        <FindReplaceIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              }}
            />
            <TextField
              margin="dense"
              id="onr"
              label="Order number"
              name="onr"
              type="text"
              fullWidth
              value={formik.values.onr}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.onr)}
              helperText={capitalize(formik.errors.onr)}
              disabled={deviceLoading || complete}
            />
            <FormControl fullWidth error={Boolean(formik.errors.type)}>
              <InputLabel id="promo-type-dropdown-label">Type</InputLabel>
              <Select
                labelId="promo-type-dropdown-label"
                id="type"
                name="type"
                value={formik.values.type}
                onChange={formik.handleChange}
                fullWidth
                className={classes.dropdown}
                disabled={deviceLoading || complete}
              >
                { Object.values(EPromoType).map((type) => (
                  <MenuItem key={type} value={type}>{type}</MenuItem>
                )) }
              </Select>
              <FormHelperText>{formik.errors.type}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={Boolean(formik.errors.from)}>
              <InputLabel id="promo-type-dropdown-label">From</InputLabel>
              <Select
                labelId="promo-from-dropdown-label"
                id="from"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
                fullWidth
                className={classes.dropdown}
                disabled={deviceLoading || complete}
              >
                {
                  fromTypes
                    .filter(from => from.type === formik.values.type)
                    .map(from =>
                      <MenuItem key={from.value} value={from.value}>
                        {from.value}
                      </MenuItem>
                    )
                }
              </Select>
              <FormHelperText>{capitalize(formik.errors.from)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={Boolean(formik.errors.to)}>
              <InputLabel id="promo-to-dropdown-label">To</InputLabel>
              <Select
                labelId="promo-to-dropdown-label"
                id="to"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
                error={formik.touched.to && Boolean(formik.errors.to)}
                fullWidth
                className={classes.dropdown}
                disabled={deviceLoading || complete}
              >
                {
                  toTypes
                    .filter((to) => {
                      return to.type === formik.values.type;
                    })
                    .map(to =>
                      <MenuItem key={to.value} value={to.value}>
                        {to.value}
                      </MenuItem>
                    )
                }
              </Select>
              <FormHelperText>{capitalize(formik.errors.to)}</FormHelperText>
            </FormControl>
            <FormControl fullWidth error={Boolean(formik.errors.reason)}>
              <InputLabel id="reason-dropdown-label">Reason</InputLabel>
              <Select
                labelId="reason-dropdown-label"
                id="reason"
                name="reason"
                value={formik.values.reason}
                onChange={formik.handleChange}
                fullWidth
                className={classes.dropdown}
                disabled={deviceLoading || complete}
              >
                <MenuItem value="">-</MenuItem>
                {
                  Object.entries(EReasons)
                    .map(([key, value]) =>
                      <MenuItem key={key} value={value}>{value}</MenuItem>
                    )
                }
              </Select>
              <FormHelperText>{capitalize(formik.errors.reason)}</FormHelperText>
            </FormControl>
            <TextField
              margin="dense"
              id="comment"
              label="Comment"
              type="text"
              fullWidth
              value={formik.values.comment}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.comment)}
              helperText={capitalize(formik.errors.comment)}
              disabled={deviceLoading || complete}
            />
            <TextField
              margin="dense"
              id="amount"
              label="Amount"
              name="amount"
              type="number"
              fullWidth
              value={formik.values.amount}
              onChange={formik.handleChange}
              error={Boolean(formik.errors.amount)}
              helperText={capitalize(formik.errors.amount)}
              disabled={deviceLoading || complete}
            />
            {
              response && <PromoResultBox error={error} codes={codes} />
            }
            {
              complete
              ? (
                <Grid container spacing={6} style={{ marginTop: 25 }}>
                  <Grid item xs={6}>
                    <Button
                      color="default"
                      variant="contained"
                      fullWidth
                      onClick={handleResetAndClose}
                      style={{ marginTop: 25 }}
                    >
                      Close
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      fullWidth
                      onClick={handleReset}
                      style={{ marginTop: 25 }}
                      startIcon={<ResetIcon />}
                    >
                      Generate new code
                    </Button>
                  </Grid>
                </Grid>
              )
              : (
                <Grid container spacing={6} style={{ marginTop: 25 }}>
                  <Grid item xs={6}>
                    <Button
                      color="default"
                      variant="contained"
                      onClick={handleResetAndClose}
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={!formik.isValid || loading}
                      fullWidth
                    >
                      Generate
                    </Button>
                  </Grid>
                </Grid>
              )
            }
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={Boolean(deviceError)}
        autoHideDuration={3000}
      >
        <Alert
          severity="error"
        >
          Failed to look up device
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddNewPromoDialog;
