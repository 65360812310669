import React, { ReactNode } from 'react';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';

interface LinkButtonProps {
  path: string,
  children: ReactNode,
  className?: string,
}

function LinkButton({ path, className, children }: LinkButtonProps) {
  return (
    <Button
      color="secondary"
      component={NavLink}
      to={path}
      className={className}
      activeClassName="active"
    >
      {children}
    </Button>
  );
};

export default LinkButton;
