import { useState, useEffect } from 'react';
import { getApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithRedirect, User } from 'firebase/auth';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import { useAuthState as useFirebaseAuthState } from 'react-firebase-hooks/auth';

interface IClaims {
  viewer?: boolean,
  editor?: boolean,
  admin?: boolean,
}

function useAuth() {
  const app = getApp(process.env?.REACT_APP_GCP_PROJECT?.includes('sandbox') ? 'sandbox' : '[DEFAULT]');

  const auth = getAuth(app);

  const [claims, setClaims] = useState({} as IClaims);
  const [user, loading, error] = useFirebaseAuthState(auth);

  useEffect(() => {
    if (!user) {
      return;
    }

    const database = getDatabase(app);

    onValue(ref(database, `metadata/${user.uid}/refreshTime`), async (data) => {
      if (!data.exists) {
        return;
      }

      const result = await user?.getIdTokenResult(true);

      setClaims(result?.claims ?? {});
    });

    return () => off(ref(database, `metadata/${user.uid}/refreshTime`));
  }, [user, app])

  const signIn = () => {
    let provider = new GoogleAuthProvider();

    provider.addScope('email');
    provider.setCustomParameters({ hd: 'radinn.com' });

    return signInWithRedirect(auth, provider);
  };

  const signOut = () => auth.signOut();

  console.log(claims);

  return {
    user,
    loading,
    claims,
    error,
    signIn,
    signOut,
  };
}

async function fetchToken(
  user: User,
  setToken: (token: string) => void, setLoading:
  (isLoading: boolean) => void)
{
  setLoading(true);
  const token = await user.getIdToken();

  setToken(token);
  setLoading(false);
};

function useToken() {
  const [token, setToken] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);

  const user = getAuth().currentUser;

  useEffect(() => {
    if (user) {
      fetchToken(user, setToken, setLoading);
    }
  }, [user]);

  return [token, loading];
}

export {
  useAuth,
  useToken,
};
